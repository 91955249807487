/* global _ */

(function () {
  'use strict';

  angular.module('canceropoleGsoApp').controller('SheetMainEventMainTabController', SheetMainEventMainTabController);

  SheetMainEventMainTabController.$inject = [
    'Principal',
    '$log',
    'SocietySearch',
    '$stateParams',
    'MainEvent',
    'CustomUtils',
    '$translate',
    '$state',
    'SheetHistoryService',
    'Axe',
    '$q',
    'ModelConfig',
    'EventManagement',
    '$uibModal',
    'ConfirmDialog',
    'entity',
    'EventSession',
    'DataUtils',
    'JQWidgetsService',
    '$timeout'
  ];

  function SheetMainEventMainTabController(
    Principal,
    $log,
    SocietySearch,
    $stateParams,
    MainEvent,
    CustomUtils,
    $translate,
    $state,
    SheetHistoryService,
    Axe,
    $q,
    ModelConfig,
    EventManagement,
    $uibModal,
    ConfirmDialog,
    entity,
    EventSession,
    DataUtils,
    JQWidgetsService,
    $timeout
  ) {
    if ($stateParams.tab !== 'main') {
      //update state param
      $stateParams.tab = 'main';
      $state.transitionTo($state.current.name, $stateParams, { notify: false });
    }

    var vm = this;

    vm.Model = {
      id: $stateParams.id,
      entity: entity,
      canEdit: Principal.hasAnyAuthority(['ROLE_ADMIN']),
      edition: {
        informations: false,
        description: false,
        otherinformation: false,
        dates: false,
        form: false
      },
      html: {}
    };
    vm.axes = Axe.query({
      page: 0,
      size: 1000,
      sort: 'name,asc'
    });

    vm.dateTimeSettings = {
      height: 30,
      culture: 'fr-FR',
      formatString: 'dd/MM/yyyy HH:mm',
      showTimeButton: true
    };

    vm.langSettings = {
      source: ['fr', 'en'],
      autoDropDownHeight: true,
      change: function () {
        if (vm.axeList) {
          $timeout(function () {
            // vm.axeList.invalidate();
            vm.createAxeList();
          }, 200);
        }
      }
    };

    vm.changePublicState = function () {
      MainEvent.update(vm.Model.entity, vm.load, vm.load);
    };
    vm.getPublicState = function () {
      if (vm.Model.entity.publique) {
        return $translate.instant('diffusion.online');
      } else {
        return $translate.instant('diffusion.offline');
      }
    };

    var onLoadSuccess = function (result) {
      for (var key in vm.Model.edition) {
        vm.Model.edition[key] = false;
      }
      vm.Model.entity = result;
      $log.debug(result);

      vm.Model.fullAddress = vm.Model.entity.address;
      if (vm.Model.entity.cp) {
        vm.Model.fullAddress += ' - ' + vm.Model.entity.cp;
      }
      if (vm.Model.entity.city) {
        vm.Model.fullAddress += ' - ' + vm.Model.entity.city;
      }

      var sheetHistory = {
        name: vm.Model.entity.name,
        type: 'mainevent',
        id: vm.Model.id
      };
      SheetHistoryService.add(sheetHistory);
    };

    vm.edit = function (win) {
      vm.Model.edition[win] = true;
    };

    vm.save = function () {
      if (vm.Model.entity.freeRegistration === true) {
        vm.Model.entity.billManagement = false;
      }

      return MainEvent.update(vm.Model.entity, vm.load, vm.load);
    };

    vm.load = function (first) {
      if (first === true) {
        //premier chargement, pas la peine de charger l'entité
        //mais il faut quand même charger le reste
        onLoadSuccess(vm.Model.entity);
      } else {
        $state.go($state.current, {}, { reload: true });
      }
    };
    vm.cancel = function () {
      vm.load();
    };

    vm.getAxeField = function () {
      if (vm.Model.entity.lang === 'en') {
        return 'nameEn';
      }
      return 'name';
    };

    vm.createAxeList = function () {
      vm.axesSettings = {
        width: '100%',
        height: 200,
        displayMember: vm.getAxeField(),
        valueMember: 'id',
        multiple: true,
        checkboxes: true,
        source: vm.axes,
        created: function (args) {
          vm.axeList = args.instance;
          vm.initAxeList();
        },
        checkChange: function (event) {
          if (event.args) {
            var args = event.args;

            var index = null;
            for (var i = 0; i < vm.Model.entity.axes.length; i++) {
              var axe = vm.Model.entity.axes[i];
              if (axe.id === args.value) {
                index = i;
                break;
              }
            }

            if (args.checked === true && index === null) {
              vm.Model.entity.axes.push({
                id: args.value,
                name: args.label
              });
            } else if (args.checked === false && index !== null) {
              vm.Model.entity.axes.splice(index, 1);
            }
          }
        },
        bindingComplete: function (e) {
          if (vm.axeList) {
            vm.initAxeList();
          }
        }
      };
    };

    vm.createAxeList();
    vm.initAxeList = function () {
      angular.forEach(vm.Model.entity.axes, function (axe) {
        vm.axeList.checkItem(axe.id);
      });
    };

    var societySource = {
      datatype: 'json',
      datafields: [
        {
          name: 'name'
        }
      ],
      id: 'id',
      url: 'api/_search/',
      data: {
        size: '20'
      }
    };

    var societyDataAdapter = new $.jqx.dataAdapter(societySource, {
      // jshint ignore:line
      loadServerData: function (serverdata, source, callback) {
        SocietySearch.query(
          {
            query: vm.societyComboBoxSettings.searchString,
            page: 0,
            size: 20
          },
          function (result) {
            callback({
              records: result
            });
          }
        );
      }
    });

    vm.societyComboBoxSettings = {
      displayMember: 'name',
      valueMember: 'id',
      minLength: 1,
      //multiSelect: true,
      remoteAutoComplete: true,
      //remoteAutoCompleteDelay: 100,
      source: societyDataAdapter,
      search: function (searchString) {
        searchString = searchString.replaceAll('/', '//');
        vm.societyComboBoxSettings.searchString = searchString;
        societyDataAdapter.dataBind();
      },
      created: function (args) {
        vm.societyComboBox = args.instance;
        angular.forEach(vm.Model.entity.societies, function (item) {
          vm.societyComboBox.val(item.name);
        });
      }
    };

    vm.societyEventChangeHandler = function () {
      vm.Model.entity.societies = [];
      var selection = vm.societyComboBox.getSelectedItem();
      if (selection.originalItem) {
        vm.Model.entity.societies.push(selection.originalItem);
      }
    };

    var managementSource = {
      datatype: 'json',
      url: 'api/public/main-events/' + $stateParams.id + '/managements',
      data: {
        size: 1000
      },
      sort: function (column, direction) {
        var records = vm.managementsDataAdapter.records;

        if (direction === 'ascending' || direction === 'asc') {
          direction = true;
        }
        if (direction === 'descending' || direction === 'desc') {
          direction = false;
        }

        if (direction != null) {
          records.sort(function (value1, value2) {
            if (column === 'name') {
              value1 = String(value1['lastName']).toLowerCase();
              value2 = String(value2['lastName']).toLowerCase();
            } else {
              value1 = String(value1[column]).toLowerCase();
              value2 = String(value2[column]).toLowerCase();
            }
            if (direction === true) {
              return value1.localeCompare(value2);
            } else {
              return value2.localeCompare(value1);
            }
          });
          vm.managementsDataAdapter.records = records;
        }
        vm.managementGrid.refreshdata();
      },
      datafields: [
        {
          name: 'id',
          type: 'number'
        },
        {
          name: 'firstName',
          map: 'person>firstName',
          type: 'string'
        },
        {
          name: 'lastName',
          map: 'person>lastName',
          type: 'string'
        },
        {
          name: 'type',
          type: 'string'
        },
        {
          name: 'name',
          type: 'string'
        },
        {
          name: 'mainEventId',
          type: 'number'
        },
        {
          name: 'personId',
          type: 'number'
        }
      ],
      updaterow: function (rowid, rowdata) {
        if (!CustomUtils.isEmpty(rowdata.type)) {
          EventManagement.update(
            {
              id: rowdata.id,
              mainEventId: rowdata.mainEventId,
              personId: rowdata.personId,
              type: rowdata.type
            },
            function () {
              vm.managementsDataAdapter.dataBind();
            }
          );
        } else {
          vm.managementsDataAdapter.dataBind();
        }
      },
      sortcolumn: 'name',
      sortdirection: 'asc',
      id: 'id'
    };

    vm.managementsDataAdapter = new $.jqx.dataAdapter(managementSource, {
      beforeLoadComplete: function (records) {
        vm.missingSubscriptionManager = true;
        angular.forEach(records, function (record) {
          record.name = record.firstName + ' ' + record.lastName;
          if (ModelConfig.mainEvent.management.getSubscriptionManagerType() === record.type) {
            vm.missingSubscriptionManager = false;
          }
        });
        vm.checkManagement();
      }
    });

    vm.managementTypeDataAdapter = new $.jqx.dataAdapter({
      dataType: 'array',
      localdata: ModelConfig.mainEvent.management.types,
      datafields: [
        {
          name: 'label',
          type: 'string'
        },
        {
          name: 'value',
          type: 'string'
        }
      ]
    });

    vm.managementsSettings = {
      width: '100%',
      source: vm.managementsDataAdapter,
      height: 500,
      filterable: true,
      showfilterrow: true,
      localization: JQWidgetsService.getLocalization($translate.use()),
      //columnsresize: true,
      editmode: 'Double-Click',
      selectionmode: 'multiplerows',
      sortable: true,
      //altrows: true,
      editable: vm.Model.canEdit,
      created: function (args) {
        vm.managementGrid = args.instance;
      },
      rowselect: function () {
        vm.canRemoveManagement();
      },
      rowunselect: function () {
        vm.canRemoveManagement();
      },
      columns: [
        {
          text: $translate.instant('sheet.mainevent.participations.name'),
          datafield: 'name',
          width: 220,
          cellsrenderer: function (row, column, value, html, cell, object) {
            if (!CustomUtils.isUndefinedOrNull(object.personId)) {
              var link = '<a href="#/sheet/person/' + object.personId + '?tab=main">';
              link += object.name;
              link += '</a>';
              var result = html.replace(value, link);
              return result;
            }
            return html;
          },
          editable: false
        },
        {
          text: 'Type',
          datafield: 'type',
          filtertype: 'checkedlist',
          columnType: 'dropdownlist',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxDropDownList({
              //dropDownWidth: 250,
              renderer: function (index, label, value) {
                if (index === 0) {
                  return label;
                }
                var keyFilter = ModelConfig.mainEvent.management.getLabel(value);
                return $translate.instant(keyFilter);
              }
            });
          },
          cellsrenderer: function (row, column, value, html) {
            var keyLabel = ModelConfig.mainEvent.management.getLabel(value);
            var label = $translate.instant(keyLabel);
            return html.replace(value, label);
          },
          createeditor: function (row, value, editor) {
            editor.jqxDropDownList({
              height: 25,
              source: vm.managementTypeDataAdapter,
              displayMember: 'label',
              valueMember: 'value',
              placeHolder: 'Veuillez choisir...',
              //selectedIndex: ModelConfig.mainEvent.management.getIndex(value),
              renderer: function (index, label, value) {
                return $translate.instant(label);
              },
              selectionRenderer: function (element, index, label, value) {
                return '<span style="line-height:25px">' + $translate.instant(label) + '</span>';
              }
            });
          },
          cellvaluechanging: function (row, column, columntype, oldValue, newValue) {
            return ModelConfig.mainEvent.management.getValue(newValue);
          }
        }
      ]
    };

    vm.addManagement = function () {
      $uibModal
        .open({
          templateUrl: 'app/sheet/mainevent/dialog/management.dialog.html',
          controller: 'ManagementDialogController',
          controllerAs: 'ctrl',
          backdrop: 'static',
          resolve: {
            translatePartialLoader: [
              '$translate',
              '$translatePartialLoader',
              function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('subscription');
                return $translate.refresh();
              }
            ]
          }
        })
        .result.then(function (result) {
          EventManagement.save(
            {
              mainEventId: vm.Model.id,
              personId: result.personId,
              type: result.type
            },
            function () {
              vm.managementsDataAdapter.dataBind();
            }
          );
        });
    };

    vm.checkManagement = function () {
      vm.managementErrors = [];
      var records = vm.managementsDataAdapter.records;

      var managerType = ModelConfig.mainEvent.management.getSubscriptionManagerType();
      var freeType = ModelConfig.mainEvent.management.getFreeRegistrationType();
      var partialFreeType = ModelConfig.mainEvent.management.getPartialFreeRegistrationType();

      vm.missingSubscriptionManager = true;

      _.each(records, function (record) {
        if (record.type === freeType) {
          _.each(records, function (anotherRecord) {
            //si même nom mais pas même type de gratuité
            if (anotherRecord.name === record.name && anotherRecord.id !== record.id && anotherRecord.type === partialFreeType) {
              vm.managementErrors.push({
                label: 'canceropoleGsoApp.eventManagement.errors.multipleFreeRegistration',
                param: record.name
              });
            }
          });
        } else if (record.type === managerType) {
          vm.missingSubscriptionManager = false;
        }
      });

      if (vm.missingSubscriptionManager === true) {
        vm.managementErrors.push({
          label: 'canceropoleGsoApp.eventManagement.errors.missingInscriptionManagement'
        });
      }
    };

    vm.canRemoveManagement = function () {
      if (vm.managementGrid) {
        return vm.managementGrid.getselectedrowindexes().length > 0;
      }
      return false;
    };

    vm.tryRemoveManagement = function () {
      var indexes = vm.managementGrid.getselectedrowindexes();

      if (indexes.length > 1) {
        var confirmLabel = $translate.instant('canceropoleGsoApp.eventManagement.delete.managementQuestion', { nb: indexes.length });
        ConfirmDialog.openConfirmRemoveWithCustomMsg(confirmLabel, function () {
          // todo
          _.forEach(indexes, function (value, index) {
            if (index === indexes.length - 1) {
              vm.removeManagement(value, function () {
                vm.managementGrid.clearselection();
                vm.managementsDataAdapter.dataBind();
              });
            } else {
              vm.removeManagement(value);
            }
          });
        });
      } else if (indexes.length === 1) {
        var data = vm.managementGrid.getrowdata(indexes[0]);
        var labelKey = ModelConfig.mainEvent.management.getLabel(data.type);
        var label = $translate.instant(labelKey);
        ConfirmDialog.openConfirmRemove(data.name + ' de ' + label, function () {
          vm.removeManagement(0, function () {
            vm.managementGrid.clearselection();
            vm.managementsDataAdapter.dataBind();
          });
        });
      }
    };

    vm.removeManagement = function (index, callback) {
      var data = vm.managementGrid.getrowdata(index);
      EventManagement.remove(
        {
          id: data.id
        },
        callback
      );
    };

    vm.load(true);
  }
})();
